var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Branches"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Branches","sub-heading":"A list of branches that fall under your company."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search branches..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-branch'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Branch")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.branches.data),function(branch){return _c('div',{key:branch.id,staticClass:"box is-marginless"},[_c('columns',{staticClass:"is-mobile"},[_c('column',{staticClass:"is-half-mobile"},[_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                name: 'branch-manager',
                params: {
                  branch: branch.uuid
                }
              }}},[_vm._v(" "+_vm._s(branch.name)+" ")]),_c('p',[_c('small',{staticClass:"has-text-grey"},[_vm._v("Managed By "+_vm._s(branch.branch_manager.full_name)+" • Updated "+_vm._s(_vm._f("asCalendar")(branch.updated_at)))])])],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"street-view","label":"Staff","info":branch.users_count}})],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"helmet-safety","label":"Work Orders","info":branch.work_orders_count}})],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"people-arrows","label":"Teams","info":branch.teams_count}})],1)],1)],1)}),0),(_vm.branches.data.length)?_c('pager',{attrs:{"pageable":_vm.branches,"context":"Branch","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.branches.data.length,"with-link":"","heading":"There are no branches available for display.","to":{
          name: 'create-branch'
        },"to-text":"New Branch"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }