<template>
<loader v-bind="{ loading }" text="Loading Branches">
  <columns>
    <column>
      <page-heading
          heading="Branches"
          sub-heading="A list of branches that fall under your company."
      />
    </column>
    <column class="is-5">
      <form>
        <text-input 
          v-model="filters.search"
          classes="has-addons is-rounded"
          placeholder="Search branches...">
          <template #right>
            <div class="buttons has-addons is-rounded">
              <submit-button 
                @submit="runSearch"
                class="is-rounded">
                Search
              </submit-button>
              <action-button
                v-if="$route.query.search"
                @click="clearFilters">
                <icon icon="times"/> 
              </action-button>
              <router-link 
                class="button is-rounded is-flex" 
                :to="{
                  name: 'create-branch'
                }">
                  <icon icon="plus"/>
                  <span>Branch</span>
                </router-link>
            </div>
          </template>
        </text-input>
      </form>
    </column>
  </columns>
  <columns>
    <column>
      <div class="index-rows">
        <div class="box is-marginless" v-for="branch in branches.data" :key="branch.id">
          <columns class="is-mobile">
            <column class="is-half-mobile">
              <router-link class="mr-2" :to="{
                name: 'branch-manager',
                params: {
                  branch: branch.uuid
                }
              }">
                {{ branch.name }}
              </router-link>
              <p>
                <small class="has-text-grey">Managed By {{ branch.branch_manager.full_name }} • Updated {{ branch.updated_at |
                  asCalendar }}</small>
              </p>
            </column>
            <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
              <index-info 
                icon="street-view" 
                label="Staff" 
                :info="branch.users_count"
              />
            </column>
            <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
              <index-info 
                icon="helmet-safety" 
                label="Work Orders" 
                :info="branch.work_orders_count"
              />
            </column>
            <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
              <index-info 
                icon="people-arrows" 
                label="Teams" 
                :info="branch.teams_count"
              />
            </column>
          </columns>
        </div>
      </div>

      <pager v-if="branches.data.length" :pageable="branches" context="Branch" jump-controls @nav="goToPage" />

      <no-items-to-display
        :item-count="branches.data.length"
        with-link
        heading="There are no branches available for display."
        :to="{
          name: 'create-branch'
        }"
        to-text="New Branch"
      />

    </column>
  </columns>
</loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    branches: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadBranches()
  },

  methods: {
    loadBranches() {
      get('v1' + this.$route.fullPath, ({data}) => {
        this.branches = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'branches',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'branches',
      });
    }
  },

  watch: {
    '$route': 'loadBranches'
  }

}
</script>